import React from 'react';
import PropTypes from 'prop-types'
import { default as MaterialButton } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import CustomTypography from '../CustomTypography.component';

const useStyles = makeStyles( theme => ({
  button: {
    justifyContent: 'space-around',
    width: 'fit-content',
    padding: '12px 32px',
    height: '48px',
    textTransform: 'initial',
  },
  leftIcon: {
    paddingLeft: '24px',
  },
  rightIcon: {
    paddingRight: '24px',
  },
  buttonTextContainer: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'space-around',
    gap: '8px',
    textTransform: 'none',
  },
  ellipsisText: {
    maxWidth: ({ propStyleWidth }) => propStyleWidth ? `calc(${propStyleWidth} - 64px)` : '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  primary: {
    backgroundColor: theme.palette.primary.main2,
    color: theme.palette.grayScale.g0,
    borderColor: theme.palette.primary.main,
    borderRadius: theme.borderRadius.designSystemButton,
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
    '&:disabled':{
      backgroundColor: theme.palette.grayScale.g01,
      borderColor: theme.palette.grayScale.g250,
      color: theme.palette.grayScale.g700,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    }
  },
  primaryDark: {
    backgroundColor: theme.palette.grayScale.g0,
    color: theme.palette.grayScale.g500,
    borderColor: theme.palette.grayScale.g0,
    borderRadius: theme.borderRadius.designSystemButton,

    '&:hover':{
      backgroundColor: theme.palette.button.active,
      color: theme.palette.grayScale.g0,
      borderColor: theme.palette.button.active,
    },
    '&:disabled':{
      backgroundColor: 'rgba(232, 232, 232, 0.2)', // #E8E8E8 gris-02 20% transparent
      color: theme.palette.grayScale.g700,
    },
    '&:focus': {
      backgroundColor: theme.palette.button.active,
      color: theme.palette.grayScale.g0,
      borderColor: theme.palette.button.active,
    },
    '&:active':{
      backgroundColor: 'rgba(216, 190, 255, 0.3)', // #D8BEFF primario-03 30% transparent
      color: theme.palette.grayScale.g0,
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    borderRadius: theme.borderRadius.designSystemButton,
    color: theme.palette.grayScale.g500,
    border: '1px solid',
    '&:hover':{
      color: theme.palette.primary.main2,
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main2,
    },
    '&:disabled':{
      backgroundColor: 'transparent',
      borderColor: theme.palette.grayScale.g700,
      color: theme.palette.grayScale.g700,
    },
    '&:focus':{
      color: theme.palette.primary.main2,
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main2,
    },
    '&:active':{
      borderColor: theme.palette.primary.main2,
      color: theme.palette.primary.main2,
    }
  },
  secondaryDark: {
    backgroundColor: 'transparent',
    color: theme.palette.grayScale.g0,
    borderColor: theme.palette.grayScale.g0,
    borderRadius: theme.borderRadius.designSystemButton,
    border: '1px solid',
    '&:hover':{
      backgroundColor: 'transparent',
      color: theme.palette.tertiary.background,
      borderColor: theme.palette.tertiary.background,
    },
    '&:disabled':{
      backgroundColor: 'transparent',
      color: theme.palette.grayScale.g700,
      borderColor: theme.palette.grayScale.g700,
    },
    '&:focus':{
      backgroundColor: 'transparent',
      color: theme.palette.tertiary.background,
      borderColor: theme.palette.tertiary.background,
    },
    '&:active':{
      borderColor: theme.palette.tertiary.background,
      color: theme.palette.tertiary.background,
    }
  },
  tertiary: {
    color: theme.palette.primary.main2,
    border: '1px solid transparent',
    borderRadius: theme.borderRadius.designSystemButton,
    '&:hover':{
      backgroundColor: theme.palette.tertiary.background,
    },
    '&:focus':{
      backgroundColor: 'transparent',
    },
    '&:disabled':{
      color: theme.palette.grayScale.g700,
    },
    '&:active':{
      background: 'none',
      color: theme.palette.primary.dark
    },
  },
  tertiaryDark: {
    color: theme.palette.grayScale.g0,
    border: '1px solid transparent',
    borderRadius: theme.borderRadius.designSystemButton,
    '&:hover':{
      backgroundColor: '#634a88',
      color: theme.palette.tertiary.background,
    },
    '&:disabled':{
      color: theme.palette.grayScale.g700,
    },
    '&:focus':{
      backgroundColor: 'transparent',
      color: theme.palette.tertiary.background,
    },
    '&:active':{
      backgroundColor: 'transparent',
      color: theme.palette.tertiary.background
    }
  },
  link: {
    padding: '0.25rem 0.5rem',
    height: '2rem',
    color: theme.palette.primary.main2,
    border: '1px solid transparent',
    '&:hover':{
      color: theme.palette.primary.dark,
      backgroundColor: 'transparent',

    },
    '&:disabled':{
      color: theme.palette.grayScale.g700,
    },
    '&:focus':{
      color: theme.palette.primary.dark,
      backgroundColor: 'transparent',
      border: '1px solid',
      borderRadius: '0.25rem',
    },
    '&:active':{
      border: '1px solid transparent',
      color: theme.palette.button.active,
    },
  },
  linkDark: {
    padding: '0.25rem 0.5rem',
    height: '2rem',
    color: theme.palette.tertiary.background,
    border: '1px solid transparent',
    '&:hover':{
      color: theme.palette.grayScale.g0,
      backgroundColor: 'transparent',
    },
    '&:disabled':{
      color: theme.palette.grayScale.g700,
    },
    '&:focus':{
      color: theme.palette.grayScale.g0,
      backgroundColor: 'transparent',
      border: '1px solid',
      borderRadius: '0.25rem',
    },
    '&:active':{
      border: '1px solid transparent',
      color: theme.palette.button.active,
    },
  },
  default: {
    margin: 'auto',
    '& svg':{
      fontSize: theme.typography.size.largePlus,
    }
  },
  medium: {
    '& svg':{
      fontSize: '1rem',
    }
  },
  small: {
    height:'1.75rem',
    margin: 'auto',
    '& svg':{
      fontSize: theme.typography.size.small,
    }
  },
  chevronAlone: {
    padding: '12px',
    minWidth: 'fit-content'
  }
}));

const ButtonContent = ( { icon, size, children, isOnlyIcon, isLink } ) => {
  const classes = useStyles();

  if (isLink && children === '') {
    children = 'LINK CAN\'T BE EMPTY';
    return (
      <>
        { children }
      </>
    )
  }

  if (isOnlyIcon) {
    return (
      <>
        {
          icon === 'left'
            ?
            <ChevronLeft />
            :
            <ChevronRight />
        }
      </>
    )
  }

  if (icon === 'right') {
    return (
      <>
        { children }
        <ChevronRight className={classes[size]} />
      </>
    )
  }

  if (icon === 'left') {
    return (
      <>
        <ChevronLeft className={classes[size]}/>
        { children }
      </>
    )
  }

  return (
    <>
      { children }
    </>
  )
}

const setSizeVariant = (usesSizeVariant, size) => {
  if (!usesSizeVariant) return 'button';

  switch (size) {
    case 'medium':
      return 'body1';
    case 'small':
      return 'body2';

    default:
      return 'button';
  }
}

const Button = ({
  type = 'primary',
  linkSizeVariant = 'default',
  icon = 'none',
  theme = 'light',
  disabled,
  onClick,
  classNameProps,
  children,
  textId,
  ...otherProps }) => {
  const classes = useStyles({ propStyleWidth: otherProps?.style?.width });

  const typeClass = theme === 'dark' ? type + 'Dark' : type;

  const usesLinkVariant = type === 'link';
  const typographySizeVariant = setSizeVariant(usesLinkVariant, linkSizeVariant);
  const usesIcon = icon !== 'none';

  const iconClass = usesIcon && icon + 'Icon';

  const isOnlyIcon = children === '';
  if (isOnlyIcon && icon === 'none') icon = 'left';

  return (
    <MaterialButton
      disabled={disabled}
      onClick={onClick}
      className={
        clsx(classes.button, {
          [classes[typeClass]]: type,
          [classes[linkSizeVariant]]: usesLinkVariant,
          [classes[iconClass]]: usesIcon,
          [classes.chevronAlone]: isOnlyIcon
        }, classNameProps)
      }
      {...otherProps}
      disableRipple={type === 'link'}
    >
      <CustomTypography variant={typographySizeVariant} customColor='none'>
        <Box className={classes.buttonTextContainer}>
          <ButtonContent icon={icon} size={linkSizeVariant} isOnlyIcon={isOnlyIcon} isLink={type === 'link'}>
            <span className={classes.ellipsisText} id={textId}>{ children }</span>
          </ButtonContent>
        </Box>
      </CustomTypography>
    </MaterialButton>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'link']),
  disabled: PropTypes.bool,
  linkSizeVariant: PropTypes.oneOf(['default', 'medium', 'small']),
  onClick: PropTypes.func,
  icon: PropTypes.oneOf(['left', 'right']),
  theme: PropTypes.oneOf(['light', 'dark']),
  classNameProps: PropTypes.string,
}

export default Button;
