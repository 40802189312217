import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const USABLE_COLORS_MAP = {
  h1: { default: 'gris07', options: ['gris07', 'blanco'] },
  h2: { default: 'gris07', options: ['gris07', 'blanco'] },
  h3: { default: 'gris07', options: ['gris07', 'blanco'] },
  h4: { default: 'gris07', options: ['gris07', 'blanco'] },
  h5: { default: 'gris07', options: ['gris07', 'blanco'] },
  h6: { default: 'gris07', options: ['gris07', 'blanco'] },
  subtitle1: { default: 'gris07', options: ['gris07', 'blanco'] },
  subtitle2: { default: 'gris07', options: ['gris07', 'blanco'] },
  subtitle3: { default: 'gris07', options: ['gris07', 'blanco'] },
  body1: { default: 'gris06', options: ['gris07', 'gris06', 'gris05', 'blanco'] },
  body2: { default: 'gris06', options: ['gris06', 'gris05', 'blanco','error', 'primaryAction', 'gris07'] },
  caption1: { default: 'gris06', options: ['gris06', 'gris05', 'blanco', 'tertiary'] },
  caption2: { default: 'gris06', options: ['gris06', 'gris05', 'blanco', 'successDark'] },
  discount: { default: 'gris05', options: ['gris05'] },
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Poppins',
    color: theme.palette.grayScale.g500,
  },
  h1: {
    fontSize: '2.25rem',
    fontWeight: '600',
    lineHeight: '3rem',
    letterSpacing: '0rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
      fontWeight: '700',
      lineHeight: '2.5rem',
      letterSpacing: '0.004rem',
    },
  },
  h2: {
    fontSize: '2rem',
    fontWeight: '600',
    lineHeight: '2.75rem',
    letterSpacing: '0rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.625rem',
      fontWeight: '700',
      lineHeight: '2.25rem',
      letterSpacing: '0.008rem',
    },
  },
  h3: {
    fontSize: '29px',
    fontWeight: '600',
    lineHeight: '40px',
    letterSpacing: '0.002rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      fontWeight: '600',
      lineHeight: '2rem',
      letterSpacing: '0.01rem',
    },
  },
  h4: {
    fontSize: '1.625rem',
    fontWeight: '500',
    lineHeight: '2.25rem',
    letterSpacing: '0.004rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.375rem',
      fontWeight: '500',
      lineHeight: '1.75rem',
      letterSpacing: '0.014rem',
    },
  },
  h5: {
    fontSize: '1.438rem',
    fontWeight: '500',
    lineHeight: '2rem',
    letterSpacing: '0.01rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      fontWeight: '600',
      lineHeight: '1.75rem',
      letterSpacing: '0.018rem',
    },
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: '700',
    lineHeight: '1.75rem',
    letterSpacing: '0.024rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
      fontWeight: '700',
      lineHeight: '1.5rem',
      letterSpacing: '0.024rem',
    },
  },
  subtitle1: {
    fontSize: '1.25rem',
    fontWeight: '500',
    lineHeight: '1.5rem',
    letterSpacing: '0.004rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
      fontWeight: '500',
      lineHeight: '1.5rem',
      letterSpacing: '0.01rem',
    },
  },
  subtitle2: {
    fontSize: '1.125rem',
    fontWeight: '600',
    lineHeight: '1.5rem',
    letterSpacing: '0.008rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '1.25rem',
      letterSpacing: '0.014rem',
    },
  },
  subtitle3: {
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.25rem',
    letterSpacing: '0.014rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.938rem',
      fontWeight: '700',
      lineHeight: '1.25rem',
      letterSpacing: '0.024rem',
    },
  },
  body1: {
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    letterSpacing: '0.02rem',
    '&.strong': {
      fontWeight: '600',
    }
  },
  body2: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.022em',
    '&.strong': {
      fontWeight: '600',
    }
  },
  caption1: {
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '1.25rem',
    letterSpacing: '0.014rem',
  },
  caption2: {
    fontSize: '0.75rem',
    fontWeight: '500',
    lineHeight: '1rem',
    letterSpacing: '0.018rem',
  },
  button: {
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '1.5rem',
    letterSpacing: '0.01rem',
  },
  discount: {
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: '1.5rem',
    letterSpacing: '0.018rem',
    textDecoration: 'line-through',
  },
  gris07: {
    color: theme.palette.grayScale.g500,
  },
  gris06: {
    color: theme.palette.grayScale.g600,
  },
  gris05: {
    color: theme.palette.grayScale.g400,
  },
  blanco: {
    color: theme.palette.grayScale.g0,
  },
  error: {
    color: theme.palette.error.main
  },
  tertiary: {
    color: theme.palette.tertiary.background
  },
  primaryAction: {
    color: theme.palette.primary.main2,
  },
  successDark: {
    color: theme.palette.success.dark,
  },
}));

const checkColorVariant = (color, variant) => {
  if (USABLE_COLORS_MAP[variant].options.includes(color)){
    return color
  }

  return USABLE_COLORS_MAP[variant].default
}

export default function CustomTypography({ className, ...restProps }) {
  const customClasses = useStyles();
  const { variant, strong, theme, customColor } = restProps;
  const dark = theme === 'dark';

  const colorVariant = customColor === 'none' ? '' : checkColorVariant(customColor, variant);

  const classes = clsx({
    [customClasses[variant]]: variant,
    dark,
    strong,
    [customClasses[colorVariant]]: colorVariant,
  });

  return (
    <div className={className}>
      <Typography className={classes} {...restProps} />
    </div>
  );
}
